<template>
  <div>
    <div  style="font-size: 0.9em;color:#ff9900;margin:0px 0 10px 10px">单一结果的评测项目，只需要定义一个结果即可 </div>
    <a-table ref="TableInfo" size="small" :bordered="true"
             :columns="columns"
             rowKey="id"
             :dataSource="dataSource"
             :pagination="false"
             :loading="loading">
      <template slot="scoreList" slot-scope="text, record">
        <div v-for="item in record.scoreList" :key="item.index">
          {{ item.fromScore || 0 }}&lt;=[分值]&lt;{{ item.toScore || 'MAX' }}
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" size="small" style="margin-right:15px;" icon="edit" @click="modify(record)">修改
        </a-button>
        <a-button type="danger" size="small" @click="deleteFactor(record)" icon="delete">删除</a-button>
      </template>
    </a-table>
    <div style="margin:20px 20px;">
      <a-button type="success" size="small" @click="search(1)" icon="reload"  style="margin-right:20px">刷新</a-button>
      <a-button type="primary" size="small" @click="add" icon="save">新增因子</a-button>
    </div>
    <modify-result-factor-modal ref="modifyFactorModal" @success="editSuccess"/>
  </div>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import ModifyResultFactorModal from "./ModifyResultFactorModal";

export default {
  name: "EvaluationResultFactor",
  mixins: [ListMixin],
  components: {ModifyResultFactorModal},
  data() {
    return {
      listGetUrl: "evaluation/factorList",
      listMethod: "get",
      evaId: undefined
    };
  },
  computed: {
    columns() {
      return [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id',
          align: 'center',
        },
        {
          title: '类型名称',
          align: 'center',
          width: 200,
          dataIndex: 'factorName',
        },
        {
          title: '类型编码',
          align: 'center',
          width: 100,
          dataIndex: 'factorNo',
        },
        {
          title: '说明',
          dataIndex: 'remark',
          ellipsis: true,
        },
        {
          title: '分数配置',
          align: 'center',
          dataIndex: 'scoreList',
          scopedSlots: {customRender: 'scoreList'}
        }, {
          title: '操作',
          width: 250,
          align: 'center',
          // fixed: 'right',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }]
    }
  },
  methods: {
    init(evaId) {
      this.evaId = evaId;
      this.queryParams.evaId = evaId;
      this.queryParams.pageSize = 100;
      this.search(1)
    },
    modify(record) {
      this.$refs.modifyFactorModal.showModal(record, 'edit')
    },
    add() {
      this.$refs.modifyFactorModal.showModal({evaId: this.evaId}, 'new')
    },
    deleteFactor(record) {
      let that = this;
      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.factorName + "】吗？！",
        centered: true,
        onOk() {
          that.$postJson("evaluation/deleteFactor", [record.id]).then(() => {
            that.$message.success("删除成功");
            that.search(1);
          });
        },
      });
    },
    afterFetch(data) {
      data.records.forEach((v, index) => {
        v.scoreList = JSON.parse(v.scoreJson || '[]')
      })
    },
    editSuccess() {
      this.search(1)
    }
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
